export const ROUTES = {
  HOME: "/",
  CREATE_ORDER: "/create-order",
  MOTIVATIONAL_VIDEOS: "/motivational-videos",
};

export const COLORS = {
  PRIMARY: "#42a5f5",
  SECONDARY: "#ff8a84",
};
