import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";

import { COLORS, ROUTES } from "./constants";
import FullPageLoader from "./components/full-page-loader/full-page-loader";
import MetaTags from "./SEO/meta-tags";

import "./App.scss";

const MainLazy = lazy(() => import("./layouts/main/main"));
const HomeLazy = lazy(() => import("./pages/home/home"));
const MotivationalVideosLazy = lazy(() => import("./pages/motivational-videos/motivational-videos"));
const CreateOrderLazy = lazy(() => import("./layouts/create-order/create-order"));

const theme = createTheme({
  palette: { mode: "dark", primary: { main: COLORS.PRIMARY }, secondary: { main: COLORS.SECONDARY } },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <MetaTags baseUrlsProp={{ ru: "https://expression.transpadent.org/" }} title="Expression Aligners" />
        <Suspense fallback={<FullPageLoader />}>
          <Routes>
            <Route path={ROUTES.HOME} element={<MainLazy />}>
              <Route index element={<HomeLazy />} />
              <Route path={ROUTES.MOTIVATIONAL_VIDEOS} element={<MotivationalVideosLazy />} />
            </Route>
            <Route path={ROUTES.CREATE_ORDER} element={<CreateOrderLazy />} />
            <Route path="*" element={<Navigate replace to={ROUTES.HOME} />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </>
  );
}

export default App;
