import { ROUTES } from "../constants";

export const metaList = {
  [ROUTES.HOME]: {
    ru: {
      title: "Головна",
      desc: "Expression Aligners",
      keys: "Вхід",
      url: ROUTES.HOME,
      img: require("../assets/images/landing_page.jpg"),
      favicon: require("../assets/images/favicon.png"),
    },
  },
  [ROUTES.MOTIVATIONAL_VIDEOS]: {
    ru: {
      title: "Мотиваційні відео| Expression Aligners",
      desc: "Expression Aligners",
      keys: "відео",
      url: ROUTES.MOTIVATIONAL_VIDEOS,
      img: require("../assets/images/landing_page.jpg"),
      favicon: require("../assets/images/favicon.png"),
    },
  },
  [ROUTES.CREATE_ORDER]: {
    ru: {
      title: "Електронне замовлення елайнерів Expression",
      desc: "Електронна форма для створення замовлень елайнерів Expression лікарями-стоматологами",
      keys: "замовлення",
      url: ROUTES.CREATE_ORDER,
      img: require("../assets/images/create_order.jpg"),
      favicon: require("../assets/images/favicon.png"),
    },
  },
};
